.btnback-component{
position: absolute;
z-index: 99;
bottom: 0;
left: 0;
}
.btn-back{
    background: linear-gradient(-30deg, rgba(0, 1 , 0, 0.4),#ffc107  75%) ;
    min-width: 10rem;
    height: 5rem;
    border: none;
    border-radius: 13rem;
    cursor: pointer;
    
}
.btn-back i{
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
}

.btn-back:hover{
    transform: scale(1.1);
}
@media (max-width:1200px){
    .btnback-component{
        position: fixed;
        z-index: 99;
        bottom: 0;
        left: -4rem;
        }
        .btn-back{
            min-width: 10rem;
            height: 5rem;
        }
}
@media (max-width:750px){
    .btnback-component{
        position: fixed;
        z-index: 99;
        bottom: 0;
        left: 0;
        }
        .btn-back{
            min-width: 6rem;
            height: 4rem;
        }
}
@media (max-width:350px){

}