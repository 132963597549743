.popup{
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(49, 49, 49, 0.8);
    z-index: 99999999999;
}
.popup-container{
    width: 60%;
    height: 60%;
    background-color: rgba(19, 19, 19, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.popup .btn-x{
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 4rem !important;
    height: 4rem !important;
}
.name-modal,
.number-modal{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10rem;
    gap: 0.6rem;
}
.name-modal input,
.number-modal input{
    height: 4rem !important;
    width: 100%;
    border-radius: 2rem;
    padding-left: 1rem;
}
.popup-container form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.popup-container form button{
    width: 100% !important;
}
.popup-container .close-modal{
    width: 10rem !important; 
    margin-top: 2rem;

}

@media (max-width:1200px){

}
@media (max-width:750px){

}
@media (max-width:350px){

}
